import { Link } from "gatsby";
import React from "react";
import imageMusicalTheater from "../../assets/images/musical-theater.jpg";
import { MEDIA_QUERIES } from "../../config";
import Button from "../common/Button";
import { H2, H4, P } from "../common/Typography";

function MusicalTheater() {
  return (
    <div
      id="musical-theater"
      css={`
        display: flex;

        ${MEDIA_QUERIES.mobile} {
          flex-direction: column;
        }
      `}
    >
      <div>
        <img
          css={`
            max-width: 446px;
            width: 100%;
            height: auto;
            vertical-align: bottom;
          `}
          src={imageMusicalTheater}
          alt="Musical Theater"
        />
      </div>
      <div
        css={`
          margin-right: calc(100vw / 2 - 1024px / 2);
          height: 279px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 80px;
          flex: 1;

          ${H2} {
            margin-bottom: 12px;
          }

          ${H4} {
            margin-bottom: 16px;
          }

          ${P} {
            max-width: 324px;
            margin: 0;
          }

          ${MEDIA_QUERIES.mobile} {
            margin-right: unset;
            margin-left: unset;
            padding: 32px 16px;
          }
        `}
      >
        <H2>Musical Theater</H2>
        <H4>ONE YEAR COURSE</H4>
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: space-between;

            ${MEDIA_QUERIES.mobile} {
              flex-direction: column;
              align-items: unset;

              & > *:not(:last-child) {
                margin-bottom: 24px;
              }
            }
          `}
        >
          <P>
            Μάθε τις τεχνικές και τα μυστικά του MUSICAL THEATRE από τους
            καλύτερους του είδους.
          </P>
          <Button.Arrowed
            as={Link}
            to="/musical-theater"
            style={{
              width: 254,
            }}
          >
            Μάθετε Περισσότερα
          </Button.Arrowed>
        </div>
      </div>
    </div>
  );
}

export default MusicalTheater;
