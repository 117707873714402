import React from "react";
import Section from "../common/Section";
import { H2, P } from "../common/Typography";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { css } from "styled-components";
import ChevronDown from "../../assets/icons/chevron-down.svg";
import { MEDIA_QUERIES } from "../../config";
import Button from "../common/Button";
import { Link } from "gatsby";
import { AGE_CLASSES } from "../../data";
import { kebabCase } from "lodash";
import toGreeklishSlug from "../../utils/toGreeklishSlug";

function Item({ children, summary }) {
  return (
    <AccordionItem
      uuid={kebabCase(summary)}
      css={`
        border-top: 1px solid #000;

        &:last-child {
          border-bottom: 1px solid #000;
        }
      `}
    >
      <AccordionItemState>
        {({ expanded }) => (
          <>
            <AccordionItemHeading>
              <AccordionItemButton
                css={`
                  padding: 8px 16px 8px 8px;
                  cursor: pointer;
                  outline: none;
                  display: flex;
                  transition: opacity 250ms ease;
                  align-items: center;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 1.5;
                  ${expanded
                    ? css`
                        background: var(--accent);
                        padding: 16px;
                        font-size: 24px;
                        font-weight: 800;
                        line-height: 1;

                        & > div {
                          max-width: 108px;
                        }
                      `
                    : ""}
                `}
              >
                <div>{summary}</div>
                <ChevronDown
                  css={`
                    margin-left: auto;
                    fill: #000;
                    transition: transform 250ms ease;
                    ${expanded
                      ? css`
                          transform: rotate(180deg);
                        `
                      : ""}
                  `}
                />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              css={`
                padding: 0 16px 16px 16px;
                background: var(--accent);
              `}
            >
              {children}
            </AccordionItemPanel>
          </>
        )}
      </AccordionItemState>
    </AccordionItem>
  );
}

function AgeClasses() {
  return (
    <Section
      id="children"
      css={`
        padding: 80px 0;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        align-items: center;
        gap: 96px;

        ${H2} {
          margin-bottom: 32px;
        }

        ${MEDIA_QUERIES.mobile} {
          grid-template-columns: 1fr;
          padding: 32px 16px;
          gap: 32px;
        }
      `}
    >
      <div>
        <H2>Παιδικά Τμήματα</H2>
        <P>
          Η Σχολή μας έχει σαν στόχο να συμβάλλει στην ανάπτυξη της χορευτικής
          σκηνής στην Ελλάδα ξεκινώντας από το φυτώριο και προσφέροντας ένα
          πρόγραμμα ειδικά διαμορφωμένο για τους μικρούς μας χορευτές από 3,5
          ετών και άνω.
        </P>
      </div>
      <div>
        <Accordion
          allowZeroExpanded
          preExpanded={[kebabCase(AGE_CLASSES[0].name)]}
        >
          {AGE_CLASSES.map(ageClass => (
            <Item key={ageClass.name} summary={ageClass.name}>
              {ageClass.list.map(item => (
                <Button.Arrowed
                  as={Link}
                  to={`/${toGreeklishSlug(ageClass.name)}/${toGreeklishSlug(
                    item.name
                  )}`}
                  fluid
                  key={item.name}
                  arrowOnHoverOnly
                >
                  {item.name}
                </Button.Arrowed>
              ))}
            </Item>
          ))}
        </Accordion>
      </div>
    </Section>
  );
}

export default AgeClasses;
