import React from "react";
import Section from "../common/Section";
import { H2, P } from "../common/Typography";
import Button from "../common/Button";
import { Link } from "gatsby";
import { ADULT_CLASSES } from "../../data";
import { MEDIA_QUERIES } from "../../config";
import toGreeklishSlug from "../../utils/toGreeklishSlug";

function AdultClasses() {
  return (
    <Section
      id="adults"
      css={`
        padding: 80px 0;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        align-items: center;
        gap: 96px;

        ${H2} {
          margin-bottom: 32px;
        }

        ${MEDIA_QUERIES.mobile} {
          grid-template-columns: 1fr;
          padding: 32px 16px;
          gap: 32px;
        }
      `}
    >
      <div>
        <H2>Τμήματα Ενηλίκων</H2>
        <P>
          Απευθύνονται σε όσους επιθυμούν να ασχοληθούν με την τέχνη του χορού
          σε πολλά και διαφορετικά στυλ όπως, Κλασσικό Μπαλέτο, Σύγχρονο,
          Μοντέρνο και Jazz.
        </P>
        <P>
          Τα μαθήματα είναι διαμορφωμένα με τέτοιο τρόπο ώστε ο καθένας να βρει
          αυτό που του ταιριάζει.
        </P>
        <P>
          Το επίπεδο μαθήματων είναι για Αρχάριους, Μεσαίους, Προχωρημένους και
          μαθητές που θέλουν να ασχοληθούν Επαγγελματικά με το χορό.
        </P>
      </div>
      <div>
        {ADULT_CLASSES.map(adultClass => (
          <Button.Arrowed
            as={Link}
            to={`/adult/${toGreeklishSlug(adultClass.name)}`}
            fluid
            key={adultClass.name}
            arrowOnHoverOnly
          >
            {adultClass.name}
          </Button.Arrowed>
        ))}
      </div>
    </Section>
  );
}

export default AdultClasses;
