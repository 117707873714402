import { Link } from "gatsby";
import React from "react";
import imageSummerDancing from "../../assets/images/summer-dancing.jpg";
import { MEDIA_QUERIES } from "../../config";
import Button from "../common/Button";
import { H2, P } from "../common/Typography";

function SummerDancing() {
  return (
    <div
      id="summer-dancing"
      css={`
        display: flex;

        ${MEDIA_QUERIES.mobile} {
          flex-direction: column-reverse;
        }
      `}
    >
      <div
        css={`
          margin-left: calc(100vw / 2 - 1024px / 2);
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          ${H2} {
            margin-bottom: 24px;
          }

          ${P} {
            max-width: 372px;
            margin: 0;
            margin-bottom: 24px;
          }

          ${MEDIA_QUERIES.mobile} {
            margin-left: unset;
            padding: 32px 16px;
          }
        `}
      >
        <H2>Summer Dancing</H2>
        <P>
          Mια καλοκαιρινή ενότητα για μικρούς και μεγάλους με διαδραστικά
          προγράμματα καλλιτεχνικής εκπαίδευσης, δημιουργικών δραστηριοτήτων και
          παιχνιδιών με κεντρικό άξονα το χορό.
        </P>
        <Button.Arrowed
          as={Link}
          to="/summer-dancing"
          style={{
            width: 254,
          }}
        >
          Μάθετε Περισσότερα
        </Button.Arrowed>
      </div>
      <div>
        <img
          css={`
            max-width: 622px;
            width: 100%;
            height: auto;
            vertical-align: bottom;
          `}
          src={imageSummerDancing}
          alt="Summer Dancing"
        />
      </div>
    </div>
  );
}

export default SummerDancing;
