import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MEDIA_QUERIES } from "../../config";
import { HEROES } from "../../data";

SwiperCore.use([Navigation]);

function Hero() {
  return (
    <Swiper
      loop
      css={`
        .swiper-button-prev {
          left: 64px;
          color: black;
        }

        .swiper-button-next {
          right: 64px;
          color: black;
        }

        ${MEDIA_QUERIES.mobile} {
          .swiper-button-prev {
            left: 20px;
          }

          .swiper-button-next {
            right: 20px;
          }
        }
      `}
      slidesPerView="auto"
      navigation
    >
      {HEROES.map(hero => (
        <SwiperSlide key={hero.imageAlt}>
          <Slide src={hero.imageSrc} alt={hero.imageAlt} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

function Slide({ src, alt }) {
  return (
    <img
      css={`
        width: 100%;
        max-height: 476px;
        object-fit: cover;
        vertical-align: bottom;

        ${MEDIA_QUERIES.mobile} {
          max-height: 132px;
        }
      `}
      src={src}
      alt={alt}
    />
  );
}

export default Hero;
