import { graphql } from "gatsby";
import React from "react";
import Divider from "../components/common/Divider";
import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import AdultClasses from "../components/home/AdultClasses";
import AgeClasses from "../components/home/AgeClasses";
import Hero from "../components/home/Hero";
import MusicalTheater from "../components/home/MusicalTheater";
import News from "../components/home/News";
import SummerDancing from "../components/home/SummerDancing";

export default function IndexPage({ data }) {
  const newsArticles = data.allContentfulNewsArticle.nodes;

  return (
    <Layout>
      <SEO title="Home" />
      <main>
        <Hero />
        {newsArticles.length ? (
          <>
            <News articles={newsArticles} />
            <Divider />
          </>
        ) : null}
        <AdultClasses />
        <Divider />
        <AgeClasses />
        <Divider />
        <MusicalTheater />
        <Divider />
        <SummerDancing />
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IndexPage {
    allContentfulNewsArticle(sort: { order: ASC, fields: order }) {
      nodes {
        date(formatString: "DD/MM/YY")
        contentful_id
        order
        title
        youtubeEmbed
        featuredImageOrVideo {
          file {
            contentType
            url
          }
          fixed(width: 600) {
            src
          }
        }
      }
    }
  }
`;
