import React from "react";
import queryString from "query-string";

export default function YoutubeEmbed({ url }) {
  const params = queryString.parseUrl(url);

  return (
    <div>
      <iframe
        width="326"
        height="326"
        src={`https://www.youtube.com/embed/${params.query.v}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}
