import React from "react";
import Section from "../common/Section";
import { H2 } from "../common/Typography";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import EventCard from "../common/EventCard";
import Img from "gatsby-image";
import { DEVICES, MEDIA_QUERIES } from "../../config";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import ChevronRight from "../../assets/icons/chevron-right.svg";
import styled from "styled-components";
import { Link } from "gatsby";
import { take } from "lodash";
import YoutubeEmbed from "../common/YoutubeEmbed";

SwiperCore.use([Navigation]);

const NavButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;

  &.swiper-button-disabled {
    opacity: 0.3;
  }
`;

export function renderFeatured(article) {
  if (article.featuredImageOrVideo?.file?.contentType.includes("video")) {
    return (
      <video
        controls
        width="326"
        height="326"
        css={`
          background: black;
        `}
      >
        <source
          src={article.featuredImageOrVideo.file.url}
          type={article.featuredImageOrVideo.file.contentType}
        />
      </video>
    );
  } else if (article.featuredImageOrVideo?.file?.contentType.includes("image")) {
    return (
      <Img
        css={`
          width: 326px;
          height: 326px;
          cursor: pointer;
        `}
        fixed={article.featuredImageOrVideo?.fixed}
      />
    );
  } else if (article.youtubeEmbed) {
    return <YoutubeEmbed url={article.youtubeEmbed} />;
  }
}

export default function News({ articles }) {
  return (
    <Section
      as="section"
      css={`
        padding: 80px 0;

        ${MEDIA_QUERIES.mobile} {
          padding: 32px 16px;
        }
      `}
    >
      <H2
        css={`
          margin-bottom: 40px;
        `}
      >
        Τα νέα μας
      </H2>
      <div
        css={`
          position: relative;
        `}
      >
        <Swiper
          css={`
            width: 100%;

            .swiper-slide {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            ${MEDIA_QUERIES.desktop} {
              width: calc(100vw - 32px);
            }
          `}
          slidesPerView={1}
          spaceBetween={0}
          navigation={{
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
          }}
          breakpoints={{
            [DEVICES.mobile]: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            [DEVICES.desktop]: {
              slidesPerView: articles.length < 3 ? articles.length : 3,
              spaceBetween: 24,
            },
          }}
        >
          {take(articles, 12).map(article => (
            <SwiperSlide key={article.contentful_id}>
              <Link to={`/news/${article.contentful_id}`}>
                <EventCard
                  event={{
                    name: article.title,
                    type: article.date,
                  }}
                  renderFeatured={() => renderFeatured(article)}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          css={`
            display: ${articles.length < 4 ? "none" : "block"};

            ${MEDIA_QUERIES.mobile} {
              display: block;
            }
          `}
        >
          <NavButton
            role="button"
            className="swiper-prev"
            css={`
              left: -64px;

              ${MEDIA_QUERIES.mobile} {
                left: 0;
              }
            `}
          >
            <ChevronLeft />
          </NavButton>
          <NavButton
            role="button"
            className="swiper-next"
            css={`
              right: -64px;

              ${MEDIA_QUERIES.mobile} {
                right: 0;
              }
            `}
          >
            <ChevronRight />
          </NavButton>
        </div>
      </div>
    </Section>
  );
}
